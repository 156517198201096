<template>
  <div class="container">
    <span class="title"> {{ title }}</span>

    <div class="inputs-section">
      <div :key="radioKey" class="radio-buttons">
        <base-radio :label="null" :value="type" @input="onInput">
          {{ firstOptionText }}
        </base-radio>
        <base-radio label="date" :value="type" @input="onInput">
          {{ secondOptionText }}
        </base-radio>
      </div>

      <base-datepicker
        :key="dateKey"
        v-model="model"
        :clearable="false"
        class="date-input"
        :disabled="disabledDatePicker"
        :picker-options="pickerOptions"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: [Object, String]
    },
    deactivation: {
      type: Boolean,
      default: false
    },
    pickerOptions: {
      type: Object
    },
    swapLabel: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      type: null,
      radioKey: null,
      dateKey: null
    }
  },
  methods: {
    onInput (v) {
      /* eslint-disable indent */
      this.model = v
        ? this.$date
            .local()
            .plus({ days: 1 - +this.deactivation })
            .toISODate()
        : null
      /* eslint-enable indent */
      this.type = v
      this.radioKey = this.$key()
      this.dateKey = this.$key()
    }
  },
  computed: {
    disabledDatePicker () {
      return !this.model
    },
    title () {
      return this.deactivation
        ? this.$t('user_management.section.activation.deactivate_title')
        : this.$t('user_management.section.activation.activate_title')
    },
    firstOptionText () {
      return this.deactivation ^ this.swapLabel
        ? this.$t('user_management.section.activation.manually')
        : this.$t('user_management.section.activation.immediately')
    },
    secondOptionText () {
      return this.deactivation
        ? this.$t('user_management.section.activation.end_of_day')
        : this.$t('user_management.section.activation.this_day')
    },
    model: {
      get () {
        return this.value
      },
      set (v) {
        this.$emit('input', v)
      }
    }
  },
  mounted () {
    if (this.model) {
      this.type = 'date'
      this.radioKey = this.$key()
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  margin: 0 0 1.25rem;
  max-width: 450px;
}
.title {
  display: block;
  font-weight: 600;
  margin: 0.5rem 0 0.75rem;
}
.inputs-section {
  display: flex;
  .radio-buttons {
    display: flex;
    flex-direction: column;
  }
  .date-input {
    align-self: flex-end;
    margin-left: auto;
  }
  .el-form-item {
    margin-bottom: 0;
  }
}
</style>
