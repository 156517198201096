<template>
  <base-section :title="$t('user_management.section.personal.title')" :is-flex="true">
    <el-form ref="form" :model="form" @submit.prevent.native="null" autocomplete="off">
      <el-row :gutter="24">
        <el-col :span="12">
          <base-input
            :label="$t('general.label.first_name')"
            ref="firstname"
            prop="firstname"
            v-model="form.firstname"
            :validations="isFirstNameRequired ? $v.form.firstname : null"
            :required="isFirstNameRequired"
            name="firstname"
            @keyupEnter="$emit('submit')"
          />
        </el-col>
        <el-col :span="12">
          <base-input
            :label="$t('general.label.last_name')"
            ref="lastname"
            prop="lastname"
            v-model="form.lastname"
            :validations="isLastNameRequired ? $v.form.lastname : null"
            :required="isLastNameRequired"
            name="lastname"
            @keyupEnter="$emit('submit')"
          />
        </el-col>
      </el-row>
      <el-row :gutter="24">
        <el-col :span="12">
          <base-input
            :label="$t('general.label.email_address')"
            ref="email"
            prop="email"
            v-model="form.email"
            :validations="isEmailRequired ? $v.form.email : null"
            :required="isEmailRequired"
            name="email"
            @keyupEnter="$emit('submit')"
          />
        </el-col>
        <el-col :span="12">
          <base-tel-input
            :label="$t('general.label.mobile_phone')"
            ref="phone"
            prop="phone"
            placeholder="0476 12 34 56"
            v-model="form.msisdn"
            :validations="isPhoneRequired ? $v.form.msisdn : null"
            :required="isPhoneRequired"
            name="phone"
            @submit="$emit('submit')"
          />
        </el-col>
      </el-row>
      <el-row :gutter="24">
        <el-col :span="12">
          <base-select
            v-model="form.language"
            :label="$t('general.label.language')"
            :items="languages"
            :validations="$v.form.language"
            @submit="$emit('submit')"
          >
          </base-select>
        </el-col>
        <el-col :span="12" v-if="showBirthdate">
          <base-datepicker
            :label="$t('general.label.birth_date')"
            ref="birthDate"
            prop="birthDate"
            v-model="form.birthdate"
            name="birthDate"
            :picker-options="datePickerOptions"
          />
        </el-col>
      </el-row>
      <el-row :gutter="24">
        <el-col v-if="isOspUser" :span="12">
          <base-input
            :label="$t('general.input_label.iban')"
            ref="iban"
            prop="iban"
            v-model="form.iban"
            name="iban"
            @keyupEnter="$emit('submit')"
          />
        </el-col>
      </el-row>
    </el-form>
  </base-section>
</template>

<script>
import { required, email } from 'vuelidate/lib/validators'
import { mapGetters } from 'vuex'
import { get } from 'lodash'
import { telNumber } from '@/app/helpers/validate'
import Role from '@/app/services/RolesService/Role'

export default {
  name: 'PersonalSection',
  validations () {
    return {
      form: {
        firstname: {
          required
        },
        lastname: {
          required
        },
        email: {
          required,
          email
        },
        msisdn: {
          required,
          telNumber: telNumber(this.$refs, 'phone')
        },
        language: {
          required
        }
      }
    }
  },
  props: {
    value: {
      type: [Object, Array]
    },
    showBirthdate: {
      type: Boolean,
      default: true
    },
    isPhoneRequired: {
      type: Boolean,
      default: true
    },
    isEmailRequired: {
      type: Boolean,
      default: true
    },
    isLastNameRequired: {
      type: Boolean,
      default: true
    },
    isFirstNameRequired: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    ...mapGetters({
      languages: 'i18n/languages'
    }),
    form: {
      get () {
        return this.value
      },
      set (v) {
        this.$emit('input', v)
      }
    },
    datePickerOptions () {
      return {
        disabledDate: value => {
          return value > this.$date.local().startOf('day').toJSDate()
        }
      }
    },
    roles () {
      return get(this.value, 'roles', []) || []
    },
    isOspUser () {
      return this.roles.includes(Role.OSPUSER)
    }
  }
}
</script>
