<template>
  <base-section
    :title="$t('user_management.section.address.title_' + type)"
    :key="key"
  >
    <el-row :gutter="24">
      <el-col :span="24" v-if="type === 'work'">
        <base-checkbox
          :label="$t('user_management.section.address.work_checkbox')"
          v-model="defaultAddress"
        />
      </el-col>
      <el-col :span="16">
        <base-input
          :label="$t('general.label.street_name')"
          ref="streetName"
          prop="streetName"
          v-model="form[type + 'Street']"
          :validations="$v.form[type + 'Street']"
          :disabled="disableFields"
          name="streetName"
          @submit="$emit('submit')"
        />
      </el-col>
      <el-col :span="8">
        <base-input
          :label="$t('general.label.street_number')"
          type="text"
          ref="streetNumber"
          prop="streetNumber"
          v-model="form[type + 'StreetNumber']"
          :validations="$v.form[type + 'StreetNumber']"
          :disabled="disableFields"
          name="streetNumber"
          @submit="$emit('submit')"
        />
      </el-col>
    </el-row>
    <el-row :gutter="24">
      <el-col :span="8">
        <base-input
          :label="$t('general.label.zip_code')"
          ref="zipCode"
          prop="zipCode"
          v-model="form[type + 'Zipcode']"
          name="zipCode"
          :disabled="disableFields"
          :validations="$v.form[type + 'Zipcode']"
          @submit="$emit('submit')"
        />
      </el-col>
      <el-col :span="16">
        <base-input
          :label="$t('general.label.city')"
          ref="city"
          prop="city"
          v-model="form[type + 'City']"
          :disabled="disableFields"
          :validations="$v.form[type + 'City']"
          name="city"
          @submit="$emit('submit')"
        />
      </el-col>
    </el-row>
  </base-section>
</template>
<script>
import { requiredIf } from 'vuelidate/lib/validators'
import { mapGetters } from 'vuex'

export default {
  name: 'AddressSection',
  data () {
    return {
      key: '',
      defaultAddress: false
    }
  },
  props: {
    value: {
      type: Object
    },
    type: {
      type: String,
      default: 'home'
    }
  },
  validations () {
    if (this.type === 'work') {
      return {
        form: {
          workStreet: {
            required: requiredIf(function (nestedModel) {
              return !!nestedModel.workStreetNumber || !!nestedModel.workCity || !!nestedModel.workZipcode
            })
          },
          workStreetNumber: {
            required: requiredIf(function (nestedModel) {
              return !!nestedModel.workStreet || !!nestedModel.workCity || !!nestedModel.workZipcode
            })
          },
          workCity: {
            required: requiredIf(function (nestedModel) {
              return !!nestedModel.workStreet || !!nestedModel.workStreetNumber || !!nestedModel.workZipcode
            })
          },
          workZipcode: {
            required: requiredIf(function (nestedModel) {
              return !!nestedModel.workStreet || !!nestedModel.workStreetNumber || !!nestedModel.workCity
            })
          }
        }
      }
    } else {
      return {
        form: {
          homeStreet: {
            required: requiredIf(function (nestedModel) {
              return !!nestedModel.homeStreetNumber || !!nestedModel.homeCity || !!nestedModel.homeZipcode
            })
          },
          homeStreetNumber: {
            required: requiredIf(function (nestedModel) {
              return !!nestedModel.homeStreet || !!nestedModel.homeCity || !!nestedModel.homeZipcode
            })
          },
          homeCity: {
            required: requiredIf(function (nestedModel) {
              return !!nestedModel.homeStreet || !!nestedModel.homeStreetNumber || !!nestedModel.homeZipcode
            })
          },
          homeZipcode: {
            required: requiredIf(function (nestedModel) {
              return !!nestedModel.homeStreet || !!nestedModel.homeStreetNumber || !!nestedModel.homeCity
            })
          }
        }
      }
    }
  },
  watch: {
    defaultAddress (v) {
      if (this.type !== 'work') {
        return false
      }
      if (v) {
        this.form = {
          ...this.form,
          workCity: this.defaultWorkAddress.city,
          workZipcode: this.defaultWorkAddress.zipCode,
          workStreet: this.defaultWorkAddress.streetName,
          workStreetNumber: this.defaultWorkAddress.streetNumber
        }
        // this.form = cloneDeep(this.defaultWorkAddress)
        this.key = this.$key()
      } else {
        this.form = {
          ...this.form,
          ...this.defaultAddressObject()
        }
        this.key = this.$key()
      }
    }
  },
  computed: {
    ...mapGetters({
      defaultWorkAddress: 'corporateAccount/address'
    }),
    disableFields () {
      return this.defaultAddress && this.type === 'work'
    },
    form: {
      get () {
        return this.value
      },
      set (v) {
        this.$emit('input', v)
      }
    }
  },
  methods: {
    defaultAddressObject () {
      const object = {}
      object[this.type + 'Street'] = ''
      object[this.type + 'StreetNumber'] = ''
      object[this.type + 'Zipcode'] = ''
      object[this.type + 'City'] = ''
      return object
    }
  }
}
</script>

<style scoped>

</style>
