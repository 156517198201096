import { render, staticRenderFns } from "./AddressSection.vue?vue&type=template&id=73effef6&scoped=true"
import script from "./AddressSection.vue?vue&type=script&lang=js"
export * from "./AddressSection.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "73effef6",
  null
  
)

export default component.exports